.blackBackground {
  /* background-image: url(../../assets/img/add1.png); */
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  height: 100%;
  width: 100vw;
  background-position: center;
  background-color: black;
  top: 0vh;
  left: 0%;
  /* opacity: 50%; */
  z-index: 998;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 959px) {
  .displayImage {
    max-width: 100%;
    height: 50%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
    z-index: 999;
  }
  .quitButton {
    max-width: 100%;
    width: 5%;
    height: auto;
    position: fixed;
    top: 15%;
    left: 85%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 999;
  }
}

@media screen and (min-width: 960px) and (max-width: 4000px) {
  .displayImage {
    max-width: 100%;
    height: 50%;
    position: fixed;
    top: 50%;
    left: 57%;
    transform: translate(-50%, -50%);
    cursor: default;
    z-index: 999;
  }
  .quitButton {
    max-width: 100%;
    width: 2%;
    height: auto;
    position: fixed;
    top: 10%;
    left: 80%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 999;
  }
}

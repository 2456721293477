
#adjustGrids {
  position: relative;
  left: -15px;
}

#adjustGridProfileImage {
  position: relative;
  left: -15px;
}

#backgroundContainerImage {
  background-color: "blue";
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-position: top left;
  /* width: "100vw"; */
  position: relative;
  left: -15px;
}

/* #backgroundContainerImage {
  background-color: "blue";
  background-size: 90% auto;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  left: -15px;
} */

#burgerContainer {
  position: absolute;
  top: 0px;
  left: 90vw;
}

#burgerMenu {
  width: 40px;
  height: 50px;
  cursor: pointer;
  color: black;
}

#profilePicture {
  border-radius: 100%;
  border: 7px solid white;
  width: 30vw;
  height: 30vw;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: none;
  object-fit: cover;
  margin-top: 20px;
}

#textContainer {
  text-align: center;
  white-space: normal;
  max-width: 430px;
  padding-left: 10px;
  padding-right: 10px;
  margin: '0 auto'; 
  word-break: normal;
  color: white;

}

#textName {
  font-weight: bold;
  font-size: 18px;
}
#textPosition {
  font-size: 16px;
  margin-top: -15px;
}

#textCompany {
  font-weight: bold;
  font-size: 18px;
  margin-top: -15px;

}

#actionButtonsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.actionButtons {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

#qrCode {
  width: 128px;
  height: 128px;
  margin-top: -15px;
}

#quoteButton {
  cursor: pointer;
  width: 200px;
}

@media screen and (min-width: 320px) and (max-width: 340px) {


  #qrCode {
    width: 110px;
    height: 110px;
    margin-top: -50px;
    margin-bottom: -5px;
  }

  #burgerMenu {
    width: 40px;
    height: 50px;
    z-index: 9999;
    cursor: pointer;
    color: black;
    margin-left: 0;
  }

  #backgroundContainerImage {
    background-size: 100vw auto;
    background-repeat: no-repeat;
    background-position: top left;
    position: relative;
    left: -15px;
  }

  #profilePicture {
    border-radius: 100%;
    border: 7px solid white;
    width: 28vw;
    height: 28vw;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #adjustGrids {
    position: relative;
    left: -25px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: -25px;
  }

  .profileContainer {
    position: absolute;
    top: 340px;
    left: 51.5%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 35px;
    height: 35px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 20px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 130px;
    height: 130px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #textName {
    font-weight: bold;
    font-size: 15px;
  }
  #textPosition {
    font-size: 13px;
    margin-top: -15px;
  }
  
  #textCompany {
    font-weight: bold;
    font-size: 15px;
    margin-top: -15px;
  
  }

  #profileCounter {
    position: relative;
    left: 0px;
    margin-top: 10px;
  }

  #profileCompany {
    font-weight: bold;
    color: white;
    font-size: 20px;
  }

  .actionButtons {
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-bottom: -15px;
  }

  .socialMediaButtons {
    width: 20px;
    height: 20px;
  }

  #quoteButton {
    cursor: pointer;
    width: 160px;
  }
}

@media screen and (min-width: 341px) and (max-width: 359px) {


  #qrCode {
    width: 110px;
    height: 110px;
    margin-top: -50px;
  }

  #burgerMenu {
    width: 40px;
    height: 50px;
    z-index: 9999;
    cursor: pointer;
    color: black;
    margin-left: 0;
  }

  #backgroundContainerImage {
    background-size: 100vw auto;
    background-repeat: no-repeat;
    background-position: top left;
    position: relative;
    left: -15px;
  }

  #profilePicture {
    border-radius: 100%;
    border: 7px solid white;
    width: 30vw;
    height: 30vw;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #textName {
    font-weight: bold;
    font-size: 17px;
  }
  #textPosition {
    font-size: 15px;
    margin-top: -15px;
  }
  
  #textCompany {
    font-weight: bold;
    font-size: 17px;
    margin-top: -15px;
  
  }

  #adjustGrids {
    position: relative;
    left: -25px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: -25px;
  }

  .profileContainer {
    position: absolute;
    top: 340px;
    left: 51.5%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 35px;
    height: 35px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 20px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 130px;
    height: 130px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #menuIcon {
    z-index: 10000;
    position: absolute;
    left: 80%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    top: 2%;
    color: black;
  }

  .actionButtons {
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-bottom: -15px;
  }

  .socialMediaButtons {
    width: 27px;
    height: 27px;
  }

}

@media screen and (min-width: 360px) and (max-width: 400px) {


  #qrCode {
    width: 110px;
    height: 110px;
    margin-top: -50px;
  }

  #burgerMenu {
    width: 40px;
    height: 50px;
    z-index: 9999;
    cursor: pointer;
    color: black;
    margin-left: 0;
  }

  #backgroundContainerImage {
    background-size: 100vw auto;
    background-repeat: no-repeat;
    background-position: top left;
    position: relative;
    left: -15px;
  }

  #profilePicture {
    border-radius: 100%;
    border: 7px solid white;
    width: 30vw;
    height: 30vw;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #adjustGrids {
    position: relative;
    left: -25px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: -25px;
  }

  .profileContainer {
    position: absolute;
    top: 340px;
    left: 51.5%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 35px;
    height: 35px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 20px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 130px;
    height: 130px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  .profileText {
    width: 85%;
  }
  #profileName {
    font-weight: bold;
    color: white;
    font-size: 22px;
  }
  #profilePosition {
    color: white;
    font-size: 20px;
    width: 100%;
    margin-top: -25px;
  }
  #profileCounter {
    position: relative;
    left: 0px;
    margin-top: 10px;
  }

  #profileCompany {
    font-weight: bold;
    color: white;
    font-size: 20px;
  }

  #qr {
    width: 128px;
    height: 128px;
    margin-top: -15px;
  }

  #menuIcon {
    z-index: 10000;
    position: absolute;
    left: 80%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    top: 2%;
    color: black;
  }

  .socialMediaButtons {
    width: 27px;
    height: 27px;
  }
}


@media screen and (min-width: 401px) and (max-width: 660px) {

  #backgroundTemp {
    margin-left: -40px;
    margin-top: -45px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 105vw;
    height: auto;
    background-size: 430px 600px;
    background-repeat: no-repeat;
    background-position: 63% center;
    object-fit: cover;
  }

  #backgroundImg {
    height: 1080px;
    background-size: 450px auto;
    background-repeat: no-repeat;
    background-position: 63% center;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    max-width: 460px;
    height: auto;
    min-height: 800px;
  }

  .whiteBoldText {
    font-weight: bold;
    color: white;
    font-size: 15px;
  }

  .flexbox_card {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
  }
  .profileContainer {
    position: absolute;
    top: 390px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 35px;
    height: 35px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 20px;
    width: 400px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 145px;
    height: 145px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #adjustGrids {
    position: relative;
    left: -25px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: -25px;
  }

  .profileText {
    width: 72%;
    height: 130px;
  }
  #profileName {
    font-weight: bold;
    color: white;
    font-size: 22px;
  }
  #profilePosition {
    color: white;
    font-size: 20px;
    width: 100%;
    margin-top: -15px;
  }
  #profileCounter {
    position: relative;
    left: 0px;
    margin-top: 50px;
  }

  #profileCompany {
    font-weight: bold;
    color: white;
    font-size: 22px;
  }

  #qr {
    margin-top: 0px;
    width: 164px;
    height: 164px;
  }

  #menuIcon {
    z-index: 10000;
    position: absolute;
    left: 78%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    top: 0%;
    color: black;
  }

  .socialMediaButtons {
    width: 27px;
    height: 27px;
  }
}

@media screen and (min-width: 661px) and (max-width: 959px) {


  #burgerContainer {
    position: absolute;
    top: 0px;
    left: 68vw;
  }

  #burgerMenu {
    width: 40px;
    height: 50px;
    cursor: pointer;
    color: black;
  }

  #backgroundContainerImage {
    background-size: 450px auto;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    left: 0px;
  }

  #profilePicture {
    border-radius: 100%;
    border: 7px solid white;
    width: 20vw;
    height: 20vw;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #adjustGrids {
    position: relative;
    left: 0px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: 0px;
  }


  .flexbox_card {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
  }
  .profileContainer {
    position: absolute;
    top: 450px;
    left: 49.5%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 40px;
    height: 40px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 20px;
    width: 400px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 145px;
    height: 145px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  .profileText {
    width: 45%;
    height: 135px;
  }
  #profileName {
    font-weight: bold;
    color: white;
    font-size: 25px;
  }
  #profilePosition {
    color: white;
    font-size: 20px;
    width: 100%;
    margin-top: -15px;
  }
  #profileCounter {
    position: relative;
    left: 0px;
    margin-top: 50px;
  }

  #profileCompany {
    font-weight: bold;
    color: white;
    font-size: 25px;
  }

  #qr {
    width: 164px;
    height: 164px;
  }

  #menuIcon {
    z-index: 10000;
    position: absolute;
    left: 67%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    top: 0%;
    color: black;
  }

  .socialMediaButtons {
    width: 32px;
    height: 32px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1299px) {

  #backgroundContainerImage {
    background-color: "blue";
    background-size: 450px auto;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    left: 0px;
  }

  #profilePicture {
    margin-top: 20px;
    border-radius: 100%;
    border: 7px solid white;
    width: 145px;
    height: 145px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #adjustGrids {
    position: relative;
    left: 0px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: 0px;
  }

  .flexbox_card {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
  }
  .profileContainer {
    position: absolute;
    top: 460px;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 40px;
    height: 40px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 10px;
    width: 550px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 130px;
    height: 130px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  .profileText {
    width: 42%;
    height: 160px;
  }
  #profileName {
    font-weight: bold;
    color: white;
    font-size: 30px;
  }
  #profilePosition {
    color: white;
    font-size: 20px;
    width: 100%;
    margin-top: -28px;
  }
  #profileCounter {
    position: relative;
    left: 0px;
    margin-top: 30px;
  }

  #profileCompany {
    font-weight: bold;
    color: white;
    font-size: 25px;
  }

  #qr {
    width: 164px;
    height: 164px;
  }

  .socialMediaButtons {
    width: 31px;
    height: 31px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1599px) {

  #backgroundContainerImage {
    background-color: "blue";
    background-size: 450px auto;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    left: 0px;
  }

  #profilePicture {
    margin-top: 20px;
    border-radius: 100%;
    border: 7px solid white;
    width: 145px;
    height: 145px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #adjustGrids {
    position: relative;
    left: 0px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: 0px;
  }

  .flexbox_card {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
  }
  .profileContainer {
    position: absolute;
    top: 460px;
    left: 54%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 40px;
    height: 40px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 10px;
    width: 550px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 130px;
    height: 130px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  .profileText {
    width: 30%;
  }
  #profileName {
    font-weight: bold;
    color: white;
    font-size: 30px;
  }
  #profilePosition {
    color: white;
    font-size: 20px;
    width: 100%;
    margin-top: -30px;
  }
  #profileCounter {
    position: relative;
    left: 0px;
    margin-top: 30px;
  }

  #profileCompany {
    font-weight: bold;
    color: white;
    font-size: 25px;
  }

  #qr {
    width: 164px;
    height: 164px;
  }

  .socialMediaButtons {
    width: 31px;
    height: 31px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {

  #backgroundContainerImage {
    background-color: "blue";
    background-size: 450px auto;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    left: 0px;
  }

  #profilePicture {
    margin-top: 20px;
    border-radius: 100%;
    border: 7px solid white;
    width: 145px;
    height: 145px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #adjustGrids {
    position: relative;
    left: 0px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: 0px;
  }

  .flexbox_card {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
  }
  .profileContainer {
    position: absolute;
    top: 460px;
    left: 50.4%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 40px;
    height: 40px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 10px;
    width: 550px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  .profileText {
    width: 25%;
  }
  #profileName {
    font-weight: bold;
    color: white;
    font-size: 30px;
  }
  #profilePosition {
    color: white;
    font-size: 20px;
    width: 100%;
    margin-top: -30px;
  }
  #profileCounter {
    position: relative;
    left: 0px;
    margin-top: 30px;
  }

  #profileCompany {
    font-weight: bold;
    color: white;
    font-size: 25px;
  }

  #qr {
    width: 164px;
    height: 164px;
  }

  .socialMediaButtons {
    width: 31px;
    height: 31px;
  }
}

@media screen and (min-width: 1921px) and (max-width: 4000px) {
  
  #backgroundContainerImage {
    background-color: "blue";
    background-size: 450px auto;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    left: 0px;
  }

  #profilePicture {
    margin-top: 20px;
    border-radius: 100%;
    border: 7px solid white;
    width: 145px;
    height: 145px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  #adjustGrids {
    position: relative;
    left: 0px;
  }

  #adjustGridProfileImage {
    position: relative;
    left: 0px;
  }

  .flexbox_card {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
  }
  .profileContainer {
    position: absolute;
    top: 450px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .belowButtons {
    width: 60px;
    height: 60px;
    margin: 1%;
    cursor: pointer;
  }
  .flexb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: 10px;
    width: 550px;
  }
  #profileImage {
    border-radius: 100%;
    border: 7px solid white;
    width: 130px;
    height: 130px;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: none;
    object-fit: cover;
  }

  .profileText {
    width: 10%;
  }
  #profileName {
    font-weight: bold;
    color: white;
    font-size: 30px;
  }
  #profilePosition {
    color: white;
    font-size: 20px;
    width: 100%;
    margin-top: -30px;
  }
  #profileCounter {
    position: relative;
    left: 0px;
    margin-top: 30px;
  }

  #profileCompany {
    font-weight: bold;
    color: white;
    font-size: 25px;
  }

  #qr {
    width: 164px;
    height: 164px;
  }
  .socialMediaButtons {
    width: 31px;
    height: 31px;
  }
}


.responsive-img {
  width: 100%;
  height: auto;
}

.flexbox_card2 {
  /* background-color: red; */
  position: absolute;
  display: flex;

  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-75%, -75%);
}

#menuIconNormal {
  color: black;
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 660px) {
  #menuIconNormal {
    z-index: 10000;
    position: absolute;
    left: 78%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    top: 2%;
    color: black;
    display: block;
    display: initial;
  }
}

/* Social media badges style */
#Rounded {
  border-radius: 100%;
  background-color: transparent;
  background-size: cover;
  margin: 10px;
  cursor: pointer;
}

/* Font Style */

#profileName {
  font-family: "Poppins", sans-serif !important;
}

#profileCompany {
  font-family: "Poppins", sans-serif !important;
}

#profilePosition {
  font-family: Helvetica, sans-serif !important;
}

#visitCounter {
  margin-top: 10px;
  font-family: Helvetica, sans-serif !important;
  height: 100px;
}

#profileQR {
  font-family: "Poppins", sans-serif !important;
}

#backgroundTemp {
  font-family: "Poppins", sans-serif !important;
}

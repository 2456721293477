

 @media screen and (orientation: portrait) {
    .rows .row {
        display: inline-block;
        margin: 0px;
     }
}

@media screen and (orientation: landscape) {
    .rows .row {
        display: inline-block;
        margin: 20px;
     }
}

.parentDiv{
    font-family: 'poppins-bold', sans-serif !important;
}
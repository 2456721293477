.rows .row {
    display: inline-block;
    
 }

 .thumbnail:hover {
    /* position:relative;
    top:-25px;
    left:-35px;
    width:164px;
    height:228px;
    display:block;
    z-index:999; */
    position: relative;
    /* display:block;
    width: 74px;
    height: 134px;
    margin-left: -10px; */
    top: -15px;
    cursor: pointer;
    transition: 0.1s;
    
}

.thumbnail{
    cursor: pointer;
    top: 0px;
}

  #DisplayButtons1_1 {
    background-image: url('../../assets/img/llamame.png'); 
    background-size: cover; 
    background-color: #3792cb;
    border-radius: 100%; 
    width: 40px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position: center;
  }

  #DisplayButtons1_2 {
    background-image: url('../../assets/img/whatsapp.png'); 
    background-size: cover; 
    background-color: #3792cb;
    border-radius: 100%; 
    width: 40px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position: center;
  }

  #DisplayButtons1_3 {
    background-image: url('../../assets/img/sms.png'); 
    background-size: cover; 
    background-color: #3792cb;
    border-radius: 100%; 
    width: 40px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position: center;
  }

  #DisplayButtons1_4 {
    background-image: url('../../assets/img/direccion.png'); 
    background-color: #3792cb;
    background-size: cover; 
    border-radius: 100%; 
    width: 40px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position: center;
  }



  /* Displaybuttons 2 */

  #DisplayButtons2_1 {
    background-image: url('../../assets/img/llamar1.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons2_2 {
    background-image: url('../../assets/img/whatsapp1.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons2_3 {
    background-image: url('../../assets/img/email1.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons2_4 {
    background-image: url('../../assets/img/direccion1.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }


  /* Displaybuttons 3 */

  #DisplayButtons3_1 {
    background-image: url('../../assets/img/llamar2.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons3_2 {
    background-image: url('../../assets/img/whatsapp2.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons3_3 {
    background-image: url('../../assets/img/email2.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons3_4 {
    background-image: url('../../assets/img/direccion2.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }


  /* Displaybuttons 4 */

  #DisplayButtons4_1 {
    background-image: url('../../assets/img/llamar3.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons4_2 {
    background-image: url('../../assets/img/whatsapp3.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons4_3 {
    background-image: url('../../assets/img/email3.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }

  #DisplayButtons4_4 {
    background-image: url('../../assets/img/direccion3.png'); 
    background-size: cover; 
    border-radius: 100%; 
    width: 50px; 
    height: 60px;
    margin: 1%;
    border: 2px solid white;
  }